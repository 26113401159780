import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import styled from 'styled-components';
import { Row, Row8Col } from '../components/CustomComponents';
import ContactForm from '../components/ContactForm';

const StyledBackgroundImage = styled(BackgroundImage)`
  @media only screen and (max-width: 599px) {
    &:after,
    &:before {
      background: #9eb28f;
      background-image: unset !important;
    }
  }
`;

const Wrapper = styled.section`
  padding: 2rem 0;
`;

const FormWrapper = styled.div`
  
  background-color: #f6f6f6;
  border-radius: 2px;

  -webkit-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.25);
`;

const INTERESTED_BUYERS_QUERY = graphql`
  query InterestedBuyersQuery {
    file(relativePath: { eq: "interested-buyers-background.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2040, quality: 75) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    allMarkdownRemark(
      limit: 100
      filter: {
        frontmatter: {
          published: { ne: false }
          templateKey: { eq: "single-home" }
        }
      }
    ) {
      edges {
        node {
          frontmatter {
            title
          }
        }
      }
    }
  }
`;

export default function InterestedBuyers() {
  const data = useStaticQuery(INTERESTED_BUYERS_QUERY);

  return (
    <StyledBackgroundImage
      fluid={data.file.childImageSharp.fluid}
      id="interested-buyers"
    >
      <Wrapper>
        <Row>
          <Row8Col>
            <FormWrapper>
              <ContactForm units={data.allMarkdownRemark.edges} />
            </FormWrapper>
          </Row8Col>
        </Row>
      </Wrapper>
    </StyledBackgroundImage>
  );
}
